import { Text } from "grommet";

const Title = props => {
  return (
    <Text
      // margin="xxsmall"
      className="HelveticaNeue-BlkCn"
      textAlign={props.align ? props.align : "center"}
      size={props.size ? props.size : "xxlarge"}
      color="white"
    >
      {props.children}
    </Text>
  );
};

export default Title;
