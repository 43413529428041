import { Box, Text, Layer, Image } from "grommet";
import { Close } from "grommet-icons";
import { mediaQueries, MyButton, topBarContainer } from "../../lib/utils";
import { useRouter } from "next/router";
import { ccBackground, ccGray } from "../../pages/_app";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Dropdown } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";

export const GET_CLUB_COUNT = gql`
  query ClubsCount($query: String) {
    clubsCount(query: $query)
  }
`;

const TopBar = (props) => {
  const router = useRouter();
  const [showMenu, setShowMenu] = useState(false);

  const filtersButtonStyles = css`
    font-size: 22px; /* Increase font size for a bigger button */
    color: white !important; /* Change text color to white */
    background: none; /* Remove background color */
    border: none; /* Remove border */

    box-shadow: none !important; /* Remove button shadow */
  `;

  const itemStyle = () =>
    css`
      font-size: 16px;
      ${mediaQueries("font-size: 20px;", "xs")}
    `;

  const MenuLink = (props) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          position: "relative",
          textOverflow: "ellipsis",
          textAlign: "center",
        }}
      >
        <MyButton>
          <Text className="HelveticaNeue-BdCn" color={ccGray}>
            <div
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                color: "white",
              }}
              css={itemStyle()}
            >
              {props.children}
            </div>
          </Text>
        </MyButton>
      </div>
    );
  };

  const { loading, data, fetchMore } = useQuery(GET_CLUB_COUNT, {
    variables: {
      query: "INVITED",
    },
    fetchPolicy: "network-only",
    // notifyOnNetworkStatusChange: true
  });

  return (
    <>
      {showMenu && (
        <Layer
          full="vertical"
          position="left"
          responsive={false}
          onClickOutside={() => setShowMenu(false)}
          onEsc={() => setShowMenu(false)}
        >
          <Box
            background={ccBackground}
            fill="vertical"
            pad="large"
            gap="large"
          >
            <MyButton icon={<Close />} onClick={() => setShowMenu(!showMenu)} />
            <MyButton
              label={
                <Text size="large" className="HelveticaNeue-Cn">
                  Mon compte
                </Text>
              }
              onClick={() => router.push("/profile")}
            />
            <MyButton
              label={
                <Text size="large" className="HelveticaNeue-Cn">
                  A propos
                </Text>
              }
              onClick={() => router.push("/about")}
            />
          </Box>
        </Layer>
      )}
      {props.loggedIn && (
        <div className="w-100 d-flex flex-column" style={topBarContainer}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Box
              align="baseline"
              direction="row"
              gap="small"
              style={{ paddingRight: "12px", paddingLeft: "12px" }}
            >
              <MyButton
                label={<Image src="/static/img/user.png" width="23" />}
                onClick={() => router.push("/profile")}
              />
            </Box>

            <div
              className="h-100 w-100 d-flex py-3 justify-content-center"
              style={{
                justifyContent: "center!important",
                alignItems: "center",
              }}
            >
              <div>
                <Box align="baseline" direction="row" gap="small">
                  <Image src="/static/img/dareappwhite.png" height="32" />
                </Box>
              </div>
            </div>

            <div className="d-flex" style={{ gap: "10px" }}>
              {/*Bootstrap dropdown menu filters*/}
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  css={filtersButtonStyles}
                >
                  <FaFilter />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/?filter=all">All</Dropdown.Item>
                  <Dropdown.Item href="/?filter=sent">Organizer</Dropdown.Item>
                  <Dropdown.Item href="/?filter=received">
                    Membership
                  </Dropdown.Item>
                  <Dropdown.Item href="/?filter=invited">Invited</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopBar;
