import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {ccBackground, ccGray, ccGreen, ccTurquoise} from "../../pages/_app";
/** @jsx jsx */
import { css, jsx, ThemeProvider, useTheme } from '@emotion/react'
import {mediaQueries} from "../../lib/utils";


/**
 * Generate a generic button component with rounded borders. Background color and size should be set in the props.
 */
const DButton = props => {
    const { children, onClick, variant="primary", hoverEnabled=true, rounded=true, magnetSide=null, flat=false, noFocus=false, ...rest  } = props;

    const bgColorMapping = {
        'primary': `${ccBackground}`,
        'secondary': `${ccTurquoise}`,
        'tertiary': `${ccGreen}`,
        'transparent': `rgba(0, 0, 0, 0.3)`,
    }


    const textColorMapping = {
        'primary':`white`,
        'secondary': `${ccBackground}`,
        'tertiary': `white`,
        'transparent': `white`,
    }


    const defaultStyle = () => {


        let res =  css`
          cursor: pointer;
          padding: 6px 38px;
          color: ${textColorMapping[variant]};
          box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.98);
          border: white 1px solid;
          background: ${bgColorMapping[variant]};
          width: 160px;
          height: 45px;
          border-radius: 60px;
          font-size: 20px;
          white-space: nowrap;

          :hover {
            box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
            cursor: pointer;
            padding: 6px 38px;
            color: ${textColorMapping[variant]};
            border: 2px solid rgba(14, 219, 232, 0.8);
            background: ${bgColorMapping[variant]};
            border-radius: 60px;

          }
          

        `;

        // if noFocus is true, then do not change the button on focus
        if(noFocus){
            res = css`
              ${res};
              :focus {
                cursor: pointer;
                color: ${textColorMapping[variant]};
                box-shadow: none!important;
                border: none;
                background: ${bgColorMapping[variant]};
                background-color: ${bgColorMapping[variant]}!important;
                
              }

              :active {
                cursor: pointer;
                color: ${textColorMapping[variant]}!important;
                box-shadow: none!important;
                border: none;
                background: ${bgColorMapping[variant]};
                background-color: ${bgColorMapping[variant]}!important;
                
              }
            `;

        }

        // if magnetSide is set, the button will be positioned on the side of the screen specified by magnetSide,
        // the radius of the button will be set to 0 on the specified mangetSide and the button will be glued to the side of the screen
        // with a margin of 0px, the border of the specified magnetSide will be set to 0px
        // do the same on hover
        if (magnetSide) {
            if (magnetSide === "left") {
                res = css`
                    ${res}
                    border-radius: 0px 60px 60px 0px;
                    margin-left: 0px;
                    border-left: 0px;
                `

                //disable and override hover effect
                    res = css`
                        ${res}
                        :hover {
                            border-radius: 0px 60px 60px 0px;
                            margin-left: 0px;
                            border-left: 0px;
                        }
                    `
            } else if (magnetSide === "right") {
                res = css`
                    ${res}
                    border-radius: 60px 0px 0px 60px;
                    margin-right: 0px;
                    border-right: 0px;
                `
            }
        }

        //if hoverEnabled is set to false, diable shadow and border effects on hover
        if (!hoverEnabled) {
            res = css`
                ${res}
                :hover {
                    box-shadow: none;
                    border: none;
                }
            `
        }



        // if flat is set to true, the button wont have shadow and the border will be set to 0px
        if (flat) {
            res = css`
                ${res}
                box-shadow: none;
                border: 0px;
            `
        }

        // if size has a value of "xsmall", "small", "medium" or "large", the button will be resized accordingly

        if (props.csize === "xsmall") {
            res = css`
                ${res}
                width: 170px;
                height: 38px;
                font-size: 14px;

              ${mediaQueries("width: 200px;height: 35px;  font-size: 18px;", "xs")}
              
            `
        }

        if (props.csize === "small") {
            res = css`
                ${res}
                width: 100px;
                height: 35px;
                font-size: 14px;
              ${mediaQueries("width: 180px;height: 50px;  font-size: 24px;", "xs")}
              
            `
        }

        if (props.csize === "medium") {
            res = css`
                ${res}
                width: 160px;
                height: 45px;
                font-size: 14px;
              ${mediaQueries("width: 180px;height: 50px;  font-size: 24px;", "xs")}
              
            `
        }

        if (props.csize === "large") {
            res = css`
                ${res}
                width: 220px;
                height: 55px;
                font-size: 14px;
              ${mediaQueries("width: 180px;height: 50px;  font-size: 24px;", "xs")}
              
            `

        }



        return res;
    }




    return (
        <Button
            size={props.size}
        onClick={onClick}
            css={defaultStyle}
        {...rest}
        >

            <div className=" HelveticaNeue-BdCn d-flex align-items-center justify-content-center w-100 h-100" style={{position:"relative", top:"3px", fontWeight: 600 }}>
        {children}
            </div>
        </Button>
    );

}

export default DButton;