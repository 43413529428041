import DButton from "../buttons/DButton";
import { Image } from "grommet";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

const DBadge = (props) => {
  return (
    <DButton
      noFocus={true}
      hoverEnabled={false}
      csize={props.csize}
      variant={props.variant}
      rounded
      magnetSide={props.magnetSide}
      flat
    >
      <div className="d-flex align-items-center">
        <Image
          src={props.icon}
          width="24px"
          css={css`
            position: relative;
            top: -3px;
            left: -4px;
          `}
        />
        {props.text}
      </div>
    </DButton>
  );
};

export default DBadge;
