import { Box, Text } from "grommet";

const Member = props => {
  return (
      <>
      <Text size="small" className="HelveticaNeue-BlkCn" wordBreak="break-word">
        {props.member && props.member.pseudo}
      </Text>
      {props.team && (
        <Text size="small" className="HelveticaNeue-Cn">
          {" ("}
          {props.team}
          {")"}
        </Text>

      )}
      </>
  );
};

export default Member;
