import gql from "graphql-tag";

export const PAGE_TRACKING = gql`
  mutation CreatePageTracking(
    $pageUrl: String!
    $httpReferrer: String
    $linkRef: String
  ) {
    createPageTracking(
      pageUrl: $pageUrl
      httpReferrer: $httpReferrer
      linkRef: $linkRef
    ) {
      result
    }
  }
`;

export const pageTrack = context => {
  // PAGE TRACKING IS DISABLED
  return ;
  // let pageUrl = context.asPath;
  // let httpReferrer;
  // let linkRef = "";
  // if (context.req) httpReferrer = context.req.headers.referer;
  //
  // const ref = context.query.linkRef;
  // if (ref && ref !== undefined && ref !== "undefined") {
  //   linkRef = ref;
  // }
  //
  // context.apolloClient.mutate({
  //   mutation: PAGE_TRACKING,
  //   variables: {
  //     pageUrl: pageUrl,
  //     httpReferrer: httpReferrer,
  //     linkRef: linkRef
  //   }
  // });
};
