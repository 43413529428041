import ClubList from "../components/homePage/ClubList";
import { Box, Button, Image, Layer, Text } from "grommet";
import Head from "next/head";
import TopBar from "../components/homePage/TopBar";
import { checkLoggedIn, checkMember } from "../lib/auth";
import { withApollo } from "../lib/apollo";
import {
  MyButton,
  getOrigin,
  copyUrlArgs,
  queryToProps,
  mediaQueries,
} from "../lib/utils";
import * as gtag from "../lib/gtag";
import { useRouter } from "next/router";
import { ccBackground } from "./_app";
import { initMessaging } from "../lib/firebase";
import { useApolloClient } from "@apollo/react-hooks";
import { pageTrack } from "../lib/tracking";
import React, { useEffect, useState } from "react";
import { IconButton } from "../components/inputs/iconButton/IconButton";
import { BsChevronDown } from "react-icons/bs";

/** @jsx jsx */
import { css, jsx, ThemeProvider, useTheme } from "@emotion/react";
import { Modal } from "../components/modal/Modal";
import { mainPageCTABottom } from "../lib/common";
import { GiHamburgerMenu } from "react-icons/gi";
import { Close } from "grommet-icons";
import DButton from "../components/buttons/DButton";

const outlineBtnCss = css`
  cursor: pointer;
  // violet transparence background
  background-color: rgba(20, 20, 20, 0.4);
  color: white;
  border: 2px solid white;
  padding-right: clamp(70px, 5vw, 130px);
  padding-left: clamp(70px, 5vw, 130px);
  padding-top:  clamp( 10px, 2vw, 20px);
  padding-bottom: clamp(10px, 2vw, 20px);

  line-height: 28px;
  font-size: clamp(22px, 2vw, 38px);
  margin-bottom: 40px;
  margin-top: 10vh;
  border-radius: 30px;

}

`;

const HomePage = (props) => {
  let description = "Make your communities great again. Join private clubs.";
  let link = getOrigin(props.host);
  let image = "/static/img/dareappwhite.png";
  const router = useRouter();
  const [addButtonRightPos, updateAddButtonRightPos] = useState("10px");
  const [showMenu, setShowMenu] = useState(false);
  const [burgerSize, setBurgerSize] = useState("60");
  const [buttonSize, setButtonSize] = useState("3px");

  //window height
  const [windowHeight, setWindowHeight] = useState(0);

  const [scrollY, setScrollY] = useState(0);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible, handleScroll]);

  // on window height change set window height
  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  const onScroll = () => {
    console.log("scrolling");
    setScrollY(window.scrollY);
  };

  const backgroundStyle = css`
    display: block;
    background-position: center;
    background-size: cover;
    background-image: url("/static/img/hero-mobile.png");
    background-position: 78%;
    ${mediaQueries(
      " background-attachment:fixed;  position: absolute; width: 100vw; top: 0; left:0;",
      "sm"
    )}
  `;

  const bottomBar = css`
    background: rgba(30, 14, 71, 0.7);
    width: 100%;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center !important;
    font-size: 12px;

    ${mediaQueries(
      "justify-content: start!important; gap: 20px;   height: 60px; ",
      "sm"
    )}
  `;

  const onWindowResize = () => {
    const { innerWidth } = window;
    if (innerWidth > 720) {
      updateAddButtonRightPos(`${(innerWidth - 720) / 2 + 5}px`);
      setBurgerSize("60");
      setButtonSize("5px");
    } else {
      updateAddButtonRightPos(`10px`);
      setBurgerSize("32");
      setButtonSize("3px");
    }
  };

  const gotoConnection = () => {
    gtag.event({
      action: "connexion",
      category: "HOME",
      label: "",
    });
    let url = "/connect?register=1";
    url = copyUrlArgs(url, props);
    router.push(url);
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
  });

  if (props.loggedIn) {
    initMessaging(useApolloClient());
  } else {
    // auto-redirect on the connection page
    // window.setTimeout(gotoConnection, 2000)
  }

  const scrolltoblock = (block) => {
    const y =
      document.getElementById(block).getBoundingClientRect().top +
      window.scrollY;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const transitionFrom = (containerHeight, direction = "left") => {
    let acc = scrollY * 1.45;
    let opacity = (scrollY * 1.2) / containerHeight;

    if (direction === "left") {
      return {
        left: containerHeight - acc <= 0 ? "0px" : `${containerHeight - acc}px`,
        opacity: opacity,
      };
    } else {
      return {
        right:
          containerHeight - acc <= 0 ? "0px" : `${containerHeight - acc}px`,
        opacity: opacity,
      };
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = ccBackground;
  }, []);

  const getCTAPosition = () => {
    return 1200 - scrollY <= 0 ? "0px" : `${1200 - scrollY}px`;
  };

  return props.loggedIn ? (
    <Box
      fill="horizontal"
      pad={{ vertical: "100px", horizontal: "medium" }}
      align="center"
    >
      {visible && <TopBar {...props} />}

      <ClubList {...props} />

      <Box
        align="center"
        style={{
          position: "fixed",
          bottom: mainPageCTABottom,
          translate: "translateX(-50%)",
        }}
      >
        <IconButton
          imgSrc={"/static/img/add-button.png"}
          labelSize="small"
          disabled={false}
          bsize={"72px"}
          onClick={() => router.push(`/new-club`)}
        ></IconButton>
      </Box>
    </Box>
  ) : (
    <>
      <Head>
        <title>dare.app</title>
        <link rel="canonical" href={link} />
        {/* <meta name="robots" content="noindex" /> */}
        <meta property="og:title" content="dare" />
        <meta
          name="description"
          content="Create and join private clubs & arena"
        />
        <meta
          property="og:description"
          content="Create and join private clubs & arena"
        />
        <meta property="og:url" content={link} />
        <meta property="og:type" content="video.other" />
        <meta property="og:image" content={image} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, height=device-height"
        />

        {/* <meta property="og:image:url" content={image} />
          <meta property="og:image:secure_url" content={image} />
          <meta property="og:image:type" content="image/jpeg" /> */}
      </Head>
      <div className="landing-page" id="landing-page">
        <header className="hero main-hero text-white" css={backgroundStyle}>
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className=" title-container d-flex flex-grow-1 flex-column align-items-center justify-content-end main-header-wrapper">
              <div className="main-title-wrapper">
                <Image
                  src={"static/img/typo-white-reg2.png"}
                  style={{ width: "clamp(250px, 60vw, 497px)" }}
                />
              </div>

              <div className="main-cta-wrapper">
                <DButton size="lg" rounded onClick={gotoConnection}>
                  <span>PLAY</span>
                </DButton>
              </div>
            </div>
            <div css={bottomBar} onClick={() => scrolltoblock("first-block")}>
              <div
                className="pl-0 pl-md-3"
                style={{ position: "relative", top: "4px" }}
              >
                <BsChevronDown
                  size={20}
                  style={{ marginBottom: "5px", marginRight: "10px" }}
                />
                <Text
                  className="HelveticaNeue-BdCn nowrap main-title "
                  css={css`
                    ${mediaQueries("font-size: 21px!important;", "sm")}
                  `}
                  style={{ color: "white" }}
                >
                  private clubs & arenas
                </Text>
              </div>
            </div>
          </div>
        </header>

        <section className="d-none d-md-block spacer"></section>

        <section className="hero feature-1" id="first-block">
          <div className="hero-content">
            <h1 className="d-block d-md-none">Play. Contest. Dare.</h1>

            <div
              className="image"
              style={{
                position: "relative",
                ...transitionFrom(windowHeight, "left"),
              }}
            >
              <Image src={"static/img/landing/feature1.png"} width={"100%"} />
            </div>

            <div
              className="text"
              style={{
                position: "relative",
                ...transitionFrom(windowHeight, "right"),
              }}
            >
              <h1 className="d-none d-md-block">Play. Contest. Dare.</h1>

              <div className="text-wrapper">
                <h3>
                  With a dare arena, experiment a thrilling way to interact with
                  your communities. Play or launch endless programs of video
                  contests, challenges, battles, castings, quests, experiences,
                  flash collective actions, drops, hacks...
                </h3>

                <h3 className="HelveticaNeue-BlkCn">
                  Live. In real life. Together. In private.
                </h3>
              </div>
            </div>

            <div className="cta">
              <DButton rounded onClick={gotoConnection}>
                <span>PLAY NOW</span>
              </DButton>
            </div>
          </div>
        </section>

        <section className="hero feature-2" id="first-block">
          <div className="hero-content">
            <h1 className="d-block d-md-none text-center">
              Community channels,
              <br /> next level
            </h1>

            <div
              className="image"
              style={{
                position: "relative",
                ...transitionFrom(windowHeight * 2.2, "right"),
                marginBottom: "0px",
              }}
            >
              <Image src={"static/img/hero-2.png"} style={{ width: "100%" }} />
            </div>

            <div
              className="text"
              style={{
                position: "relative",
                ...transitionFrom(windowHeight * 2.5, "left"),
              }}
            >
              <h1 className="d-none d-md-block">
                Community channels, next level
              </h1>

              <div className="text-wrapper">
                <h3>
                  dare channels offer modern community builders an efficient
                  tool to gather fanbases, customers, users, creators from any
                  platform & engage them 100%, the private way, generating video
                  UGC, advocacy, revenues...
                </h3>

                <h3 className="HelveticaNeue-BlkCn">
                  Build your community the new way : profitable.
                </h3>
              </div>
            </div>

            <div className="cta justify-content-end">
              <DButton rounded onClick={gotoConnection}>
                <span>TRY NOW</span>
              </DButton>
            </div>
          </div>
        </section>

        {/*<section className="hero feature-3">*/}
        {/*    <div className="hero-content">*/}
        {/*        <div className="image">*/}
        {/*            <Image src={"static/img/feature3.png"} style={{ width: "314px" }} />*/}
        {/*        </div>*/}

        {/*        <div className="text">*/}
        {/*            <h1>*/}
        {/*                For neo-social community owners*/}
        {/*            </h1>*/}

        {/*            <h3>*/}
        {/*                Brands, events, media, causes, personalities : dare.app is the solution to interact and engage directly with large fanbases, build loyalty & brand love, and build state-of-the-art advocacy & influence programs turning followers into ambassadors.*/}

        {/*                On top of social media.*/}
        {/*            </h3>*/}
        {/*        </div>*/}

        {/*        <div className="cta">*/}
        {/*            CTA*/}
        {/*        </div>*/}

        {/*    </div>*/}

        {/*</section>*/}

        <footer className="hero-content footer">
          <div className="footer-content">
            <div className="d-flex flex-column text-left text-md-left justify-content-md-start ">
              <h3 className="mb-0 mb-md-4">ABOUT</h3>

              <h4>
                dare.app is powered by HATX
                <br />
                RCS Bayonne 864 464 057
                <br />
                97, Allée Théodore Monod
                <br />
                64210 Bidart, France
                <br />
              </h4>
            </div>
            <div className="d-flex flex-column text-left text-md-center justify-content-md-start ">
              <h3 className="mb-0 mb-md-4">CONTACT</h3>

              <h4> hello [at] dare.app</h4>
            </div>
            <div className="d-flex flex-column text-left text-md-right justify-content-md-start ">
              <h3 className="mb-0 mb-md-4">GDPR notice</h3>

              <h4>
                We do not collect any data or
                <br /> cookies from this page.
              </h4>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

HomePage.getInitialProps = async (context) => {
  let params = {};
  params = queryToProps(params, context.query);

  const loggedIn = checkLoggedIn(context);
  if (loggedIn) checkMember(context, "activity");
  params.loggedIn = loggedIn;

  if (!params.filter) params.filter = "all";

  const host = context.req
    ? context.req.headers.host
    : window.location.hostname;
  params.host = host;

  pageTrack(context);

  return params;
};

export default withApollo(HomePage);
