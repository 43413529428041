import { Text } from "grommet";
import { IconButton } from "./inputs/iconButton/IconButton";

const EmptyScreen = (props) => {
  return (
    <div
      style={{
        width: "clamp(280px, 80vw, 350px)",
        height: "250px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="mb-4 mx-auto">
        <div className="text-justify">
          <Text size="large" className="HelveticaNeue-BlkCn text-justify">
            {props.title}
          </Text>
        </div>
      </div>

      <div className="text-justify">
        <Text size="small" className="HelveticaNeue-BdCn text-justify">
          {props.subtitle}
        </Text>
      </div>
    </div>
  );
};

export default EmptyScreen;
