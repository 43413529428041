import { ClubCard } from "./ClubCard";
import { Box } from "grommet";
import gql from "graphql-tag";
import Loader from "../Loader";
import { useQuery } from "@apollo/react-hooks";
import LoadMore from "../LoadMore";
import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import EmptyScreen from "../EmptyScreen";

export const GET_CLUB_LIST = gql`
  query Clubs($query: String, $cursor: String) {
    clubs(first: 10, query: $query, after: $cursor) {
      totalCount
      edges {
        node {
          memberCount
          ugcCount
          amOwner
          amMember
          amInvited
          slug
          title
          landscapeImage
          portraitImage
          headerVideo {
            extPlaybackId
            encodingStatus
            publicUrl
          }
          logoImage
          owner {
            member {
              pseudo
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

const emptyScreenWording = {
  sent: {
    title:
      "Ouvre ta première dare channel pour commencer à jouer avec ta communauté.",
    subtitle:
      "Clique sur le bouton ci-dessous pour lancer une arène de défis, battle, mobilisation, action groupée, événement...ou juste interagir en jouant.",
  },
  all: {
    title: "Bienvenue !",
    subtitle:
      "Les arènes, clubs, battles... que tu lanceras ou que tu rejoindras apparaîtront ici. Tu peux lancer ta premiere arène en cliquant sur le bouton ci-dessous.",
  },
  received: {
    title:
      "Les channels dare.app dans lesquelles tu as été invité(e) et que tu rejoindras apparaitront ici.",
    subtitle:
      "En attendant, tu peux lancer ta propre dare channel en cliquant sur le bouton ci-dessous.",
  },
  invited: {
    title:
      "Les channels dare.app dans lesquelles tu as été invité(e) via dare.app apparaïtront ici.",
    subtitle:
      "En attendant, tu peux lancer ta propre dare channel en cliquant sur le bouton ci-dessous.",
  },
};

const ClubList = (props) => {
  const router = useRouter();
  const [showFirstClubModal, setShowFirstClubModal] = useState(false);
  const [canCloseFirstModal, setCanCloseFirstModal] = useState(true);

  const {
    loading: loadingOwner,
    data: owner,
    fetchMore: fmowner,
  } = useQuery(GET_CLUB_LIST, {
    variables: {
      query: "sent",
      endCursor: null,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const { loading, data, fetchMore } = useQuery(GET_CLUB_LIST, {
    variables: {
      query: props.filter || "sent",
      endCursor: null,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingmember,
    data: member,
    fetchMore: fmmember,
  } = useQuery(GET_CLUB_LIST, {
    variables: {
      query: "received",
      endCursor: null,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const onLoadMore = useCallback(() => {
    try {
      fetchMore({
        variables: {
          query: props.filter,
          cursor: data.clubs.pageInfo.endCursor,
        },
        updateQuery: (prev, next) => {
          const newEdges = next.fetchMoreResult.clubs.edges;
          const pageInfo = next.fetchMoreResult.clubs.pageInfo;
          return {
            clubs: {
              __typename: prev.clubs.__typename,
              edges: [...prev.clubs.edges, ...newEdges],
              pageInfo,
            },
          };
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  }, [data?.clubs, fetchMore]);

  let itemList = [];
  let itemSlugs = new Set();
  if (data && data.clubs) {
    for (let c of data.clubs.edges) {
      if (!itemSlugs.has(c.node.slug)) {
        itemSlugs.add(c.node.slug);
        itemList.push(
          <ClubCard club={c.node} key={c.node.slug} client={props.client} />
        );
      }
    }
  }

  useEffect(() => {
    let membersCount = member?.clubs?.edges?.length ?? null;
    let ownerCount = owner?.clubs?.edges?.length ?? null;

    if (props.loggedIn && membersCount != null && ownerCount !== null) {
      if (membersCount + ownerCount === 0) {
        setShowFirstClubModal(true);
        setCanCloseFirstModal(false);
      } else if (membersCount > 0 && ownerCount === 0) {
        setShowFirstClubModal(true);
        setCanCloseFirstModal(true);
      } else {
        setShowFirstClubModal(false);
      }
    }
  }, [owner, member]);

  return (
    <Box fill="horizontal">
      {itemList.length > 0 && (
        <Box style={{ filter: loading ? "blur(4px)" : "" }}>
          {itemList}
          <Box align="center" margin="100px" justify="end">
            {data && data.clubs && data.clubs.pageInfo.hasNextPage && (
              <LoadMore
                onLoadMore={onLoadMore}
                hasNextPage={data.clubs.pageInfo.hasNextPage}
              />
            )}
          </Box>
        </Box>
      )}
      {loading && (
        <Box
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader />
        </Box>
      )}

      {!loading && itemList.length === 0 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="d-flex flex-column align-items-center h-100">
            <EmptyScreen
              title={emptyScreenWording[props.filter].title ?? ""}
              subtitle={emptyScreenWording[props.filter].subtitle ?? ""}
            />
          </div>
        </div>
      )}
    </Box>
  );
};

export default ClubList;
