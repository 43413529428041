import { Box, Text } from "grommet";
import * as gtag from "../lib/gtag";
import {useEffect} from "react";

const LoadMore = props => {
  useEffect(() => {
    window.onscroll = function (ev) {
      if ((window.innerHeight + window.scrollY + 10) >= document.body.scrollHeight) {

          if (props.hasNextPage) {
          props.onLoadMore();
        }
      }
    };
  });

  return (
    <Box
      margin={props.margin ? props.margin : "large"}
      // alignSelf="center"
      focusIndicator={false}
      onClick={() => {
        gtag.event({
          action: "load more",
          category: "GENERIC",
          label: ""
        });
        props.onLoadMore();
      }}
    >
    </Box>
  );
};

export default LoadMore;
