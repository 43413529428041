import { Box, Text } from "grommet";

/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";

const ACTIVITY_COUNT = gql`
  query ActivitiesCount($clubSlug: String!, $query: String) {
    activitiesCount(clubSlug: $clubSlug, query: $query)
  }
`;


const ClubCounters = ({ club,totalPoints }) => {

    const countAll = useQuery(ACTIVITY_COUNT, {
        variables: {
            clubSlug: club.slug,
            query: "ALL"
        }
    });

    const separators = css`
      border: none;
      height: 1px;
      opacity: 0.5 ;
      background-color: white;
      width: 30px;
    `;

    const container = css`
      display: flex;
      gap: 0px;
      align-items: center;
    `;

    const counter = css`
     flex: 1 0 0;
      width: 80px;
    `;

  return (
    <div css={container}>
        <div css={counter} className="d-flex align-items-center justify-content-center flex-column h-100">

            <Box align="center" justify="center" alignContent="center">

                <Text size="medium" className="HelveticaNeue-BlkCn  mt-1" style={{fontSize: "16px"}}>
                    {club.memberCount}
                </Text>
                <Text size="xsmall" style={{ marginTop: "-6px" }}>
                    Membre{club.memberCount > 1 ? "s" : ""}
                </Text>

            </Box>
        </div>
        <div css={counter} className="d-flex align-items-center justify-content-center flex-column h-100">
      <Box align="center" justify="center" alignContent="center">

          <Text size="medium" className="HelveticaNeue-BlkCn mt-1" style={{fontSize: "16px"}}>
              {countAll && countAll.data && countAll.data.activitiesCount}
          </Text>
          <Text size="xsmall" style={{ marginTop: "-6px" }}>
              Dare{countAll && countAll.data && countAll.data.activitiesCount > 1 ? "s" : ""}
          </Text>
      </Box>
    </div>
        <div css={counter} className="d-flex align-items-center justify-content-center flex-column h-100">

      <Box align="center" justify="center" alignContent="center">

          <Text size="medium" className="HelveticaNeue-BlkCn  mt-1" style={{fontSize: "16px"}} >
              {club.ugcCount}
          </Text>
        <Text size="xsmall" style={{ marginTop: "-6px" }}>
          Participation{club.ugcCount > 1 ? "s" : ""}
        </Text>

      </Box>
        </div>
        { !!totalPoints && (<div css={counter} className="d-flex align-items-center justify-content-center flex-column h-100">

            <Box align="center" justify="center" alignContent="center">

                <Text size="medium" className="HelveticaNeue-BlkCn  mt-1" style={{fontSize: "16px"}} >
                    {totalPoints}
                </Text>
                <Text size="xsmall" style={{ marginTop: "-6px" }}>
                    En jeu
                </Text>

            </Box>
        </div>)}


      {/* <Box align="center" justify="center" alignContent="center">
        <Text size="medium" className="HelveticaNeue-BlkCn">
          {club.ugcCount}
        </Text>
        <Text size="xsmall" style={{ marginTop: "-6px" }}>
          contribution{club.ugcCount > 1 ? "s" : ""}
        </Text>
      </Box>
      <Box style={{ borderLeft: "1px solid white", height: "30px" }}></Box> */}
      {/*<Box align="center" justify="center" alignContent="center">*/}
      {/*  <Text size="medium" className="HelveticaNeue-BlkCn">*/}
      {/*     {club.prizemoney} €*/}
      {/*  </Text>*/}
      {/*  <Text size="xsmall" style={{ marginTop: "-6px" }}>*/}
      {/*    total en jeu*/}
      {/*  </Text>*/}
      {/*</Box>*/}
      {/*<Box align="center" justify="center" alignContent="center">*/}
      {/*  <Text size="medium" className="HelveticaNeue-BlkCn">*/}
      {/*    {club.wonCash} €*/}
      {/*  </Text>*/}
      {/*  <Text size="xsmall" style={{ marginTop: "-6px" }}>*/}
      {/*    déjà reversé*/}
      {/*  </Text>*/}
      {/*</Box>*/}
    </div>
  );
};

export default ClubCounters;
