import { Close } from "grommet-icons";
import { MyButton } from "../lib/utils";
import * as gtag from "../lib/gtag";

const CloseButton = (props) => {
  return (
    <MyButton
      id="CLOSE_BUTTON"
      icon={
        <div style={{ height: "48px", minWidth: "48px" }}>
          <Close />
        </div>
      }
      margin="small"
      onClick={(e) => {
        props.onClose(e);
        gtag.event({
          action: "close",
          category: "GENERIC",
          label: "",
        });
        e.stopPropagation();
      }}
    />
  );
};

export default CloseButton;
