import { Box, Text, Image } from "grommet";
import Router from "next/router";
import Title from "../Title";
import * as gtag from "../../lib/gtag";
import BriefBy from "../BriefBy";
import { appendOptions } from "../../services/CloudinaryService";

import { getAnimatedVideoPreview, mediaQueries } from "../../lib/utils";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useEffect } from "react";
import ClubCounters from "../clubPage/Counters";
import DBadge from "../badges/DBadge";

const cardContentWrapperStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  max-width: 225px;
  margin-bottom: calc(30% / 2);
`;

const clubCardStyle = css`
  background-size: cover !important;
  height: 76vh;

  @supports (height: 100svh) {
    height: 76dvh;
  }
`;

const cardTitleStyle = css`
  // force IOS to use the font
  font-family: "HelveticaNeue-BlkCn" !important;
  text-align: center;
  font-size: 32px;
  line-height: 35px;
  font-weight: 900;
`;

export const WelcomeCard = () => {
  return (
    <Box
      align="center"
      justify="center"
      gap="xlarge"
      height="90vh"
      onClick={() => {
        gtag.event({
          action: "Create new club",
          category: "HOME",
          label: "",
        });
        Router.push("/new-club");
      }}
    >
      <Text size="large" className="HelveticaNeue-BdCn">
        Bienvenue !
      </Text>
      <Text className="HelveticaNeue-Cn">
        Ici tu peux créer tes propres clubs
      </Text>
      {/* <Image src="/static/img/new.png" width="10px" /> */}
    </Box>
  );
};

export const ClubCard = ({ club }) => {
  const HEADER_WIDTH = 500;
  const HEADER_HEIGHT = 250;

  const getMainBoxStyle = () => {
    let url = null;

    if (club.headerVideo && club.headerVideo.extPlaybackId) {
      url = getAnimatedVideoPreview(club.headerVideo.extPlaybackId, 10, 10);
    } else if (club.landscapeImage) {
      url = appendOptions(club.landscapeImage, ["q_50", "c_fill", "g_auto"]);
    } else if (club.portraitImage) {
      url = appendOptions(club.portraitImage, ["q_50", "c_fill", "g_auto"]);
    } else {
      return css`
        position: relative;
        justify-content: center;
        align-items: center;
        background: url("/static/img/main_placeholder.jpg");
        background-position-y: 40%;
        background-position-x: 70%;
        background-size: contain;
        width: 100%;
        border-radius: 2px;

        ${clubCardStyle}
      `;
    }

    return css`
      position: relative;
      justify-content: center;
      align-items: center;
      background: linear-gradient(#0003, #0006 100%), url(${url});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      border-radius: 2px;

      ${clubCardStyle}
    `;
  };

  useEffect(() => {}, [club]);

  const target = `/club/${club.slug}`;

  const overlayedContentStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `;

  const overlayedLayoutStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
  `;

  const overlayedContentItemStyle = css`
    flex: 1;
  `;

  const getClubBadge = () => {
    let text = "Organisateur";
    let icon = "/static/img/gear.svg";
    let variant = "primary";

    if (club.amOwner) {
      return (
        <DBadge
          text={text}
          csize="xsmall"
          variant={variant}
          icon={icon}
          magnetSide={"left"}
        />
      );
    }

    if (club.amMember) {
      return null;
    }

    if (club.amInvited) {
      text = `Invitation`;
      icon = "/static/img/invited.svg";
      variant = "secondary";
      return (
        <DBadge
          text={text}
          csize="xsmall"
          variant={variant}
          icon={icon}
          magnetSide={"left"}
        />
      );
    }

    return null;
  };

  return (
    <Box
      focusIndicator={false}
      align="center"
      margin={{ vertical: "medium" }}
      pad={{ vertical: "xlarge", horizontal: "large" }}
      justify="center"
      css={getMainBoxStyle()}
      onClick={() => {
        gtag.event({
          action: "Clic club card",
          category: "HOME",
          label: target,
        });
        Router.push(target);
      }}
    >
      <div css={overlayedContentStyle}>
        <div css={overlayedLayoutStyle}>
          <div
            css={overlayedContentItemStyle}
            className="d-flex align-items-center"
          >
            <div>{getClubBadge()}</div>
          </div>
          <div css={overlayedContentItemStyle}></div>
          <div css={overlayedContentItemStyle}></div>
        </div>
      </div>
      <div>
        {club.logoImage && <Image src={club.logoImage} height="70px" />}
        <div css={cardContentWrapperStyle}>
          <BriefBy member={club.owner.member} />
          <Text css={cardTitleStyle}>{club.title}</Text>
          <ClubCounters club={club} />
        </div>
      </div>
    </Box>
  );
};
