import { Box, Text } from "grommet";
import Member from "./Member";

const BriefBy = ({ member }) => {
    return (
        <div className="d-flex">
            <div>
                <Text size="small" className="HelveticaNeue-Cn">
                    Par&nbsp;
                </Text>
            </div>
            <div>
                <Member member={member} />

            </div>

        </div>
    );
};

export default BriefBy;
